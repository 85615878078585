<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter by Transaction Type</label>
            <b-select
              v-model="filter.transaction_type"
              :options="filterTransactionTypes"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </div>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              class="mr-1"
              @click="onShowApprovalForm(row.item)"
            >
              Approval
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- modal update -->
    <b-modal
      id="modal-approval-form"
      scrollable
      no-close-on-backdrop
      title="Request Approval"
      @ok="onConfirmAction"
    >
      <ValidationObserver
        ref="form"
      >
        <form
          novalidate
          role="form"
        >
          <b-card
            no-body
            class="mb-5"
          >
            <b-tabs>
              <b-tab
                title="Request Details"
                active
              >
                <b-form-group>
                  <label for="request_status">Request Status</label>
                  <b-input
                    id="request_status"
                    v-model="paymentRequest.request_status"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="request_date">Request Date</label>
                  <b-input
                    id="request_date"
                    v-model="paymentRequest.created_at"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="request_payee">Request Payee</label>
                  <b-input
                    id="request_payee"
                    v-model="paymentRequest.request_payee"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="amount">Request Amount</label>
                  <b-input
                    id="request_amount"
                    v-model="paymentRequest.request_amount"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="budget_name">Budget Name</label>
                  <b-input
                    id="budget_name"
                    v-model="paymentRequest.budget_name"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="budget_actual_name">Budget Actual</label>
                  <b-input
                    id="budget_actual_name"
                    v-model="paymentRequest.budget_actual_name"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="item_name">Item Description</label>
                  <b-input
                    id="item_name"
                    v-model="paymentRequest.item_name"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="request_description">Request Description</label>
                  <b-form-textarea
                    id="request_description"
                    v-model="paymentRequest.request_description"
                    rows="6"
                    max-rows="6"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="payment_mode">Payment Mode</label>
                  <b-input
                    id="payment_mode"
                    v-model="paymentRequest.payment_mode"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group v-if="paymentRequest.payment_mode === 'Check' || paymentRequest.payment_mode === 'Cash'">
                  <label for="payment_action">Payment Action</label>
                  <b-input
                    id="payment_action"
                    v-model="paymentRequest.payment_action"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group v-if="paymentRequest.payment_action === 'Deposit' || paymentRequest.payment_mode === 'Online Banking'">
                  <label for="bank_account_number">Bank Account Number</label>
                  <b-input
                    id="bank_account_number"
                    v-model="paymentRequest.bank_account_number"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group v-if="paymentRequest.payment_action === 'Deposit' || paymentRequest.payment_mode === 'Online Banking'">
                  <label for="bank_account_name">Bank Account Name</label>
                  <b-input
                    id="bank_account_name"
                    v-model="paymentRequest.bank_account_name"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group v-if="paymentRequest.payment_action === 'Deposit' || paymentRequest.payment_mode === 'Online Banking'">
                  <label for="bank">Bank</label>
                  <b-input
                    id="bank"
                    v-model="paymentRequest.bank"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group v-if="paymentRequest.approval_one_name !== null">
                  <label for="approval_one_name">Noted By</label>
                  <b-input
                    id="approval_one_name"
                    v-model="paymentRequest.approval_one_name"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group v-if="paymentRequest.approval_one_name !== null">
                  <label for="approval_one_at">Noted At</label>
                  <b-input
                    id="approval_one_at"
                    v-model="paymentRequest.approval_one_at"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group v-if="paymentRequest.approval_one_name !== null">
                  <label for="approval_one_remarks">Noted By Remarks</label>
                  <b-textarea
                    id="approval_one_remarks"
                    v-model="paymentRequest.approval_one_remarks"
                    rows="6"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="approval_two_name">Approved By</label>
                  <b-input
                    id="approval_two_name"
                    v-model="paymentRequest.approval_two_name"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="approval_two_at">Approved At</label>
                  <b-input
                    id="approval_two_at"
                    v-model="paymentRequest.approval_two_at"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="approval_two_remarks">Approved By Remarks</label>
                  <b-textarea
                    id="approval_two_remarks"
                    v-model="paymentRequest.approval_two_remarks"
                    rows="6"
                    :disabled="true"
                  />
                </b-form-group>

              </b-tab>

              <b-tab
                title="Tax Details"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="request type"
                  vid="transaction_type"
                  rules="required"
                >
                  <b-form-group>
                    <label for="transaction_type">Transaction Type</label>
                    <b-form-select
                      id="transaction_type"
                      v-model="paymentRequest.transaction_type"
                      :options="transactionTypes"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isBusy"
                      @change="onTransactionTypeChange"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please Select Request Type --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="payment nature"
                  vid="payment_nature"
                  :rules="updateRules"
                >
                  <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                    <label for="payment_nature">Payment Nature</label>
                    <b-form-select
                      id="payment_nature"
                      v-model="paymentRequest.payment_nature"
                      :options="paymentNatures"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isBusy || paymentRequest.transaction_type === 'Without 2307'"
                      @change="onChangeParameter"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please Select Payment Nature --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="document type"
                  vid="document_type"
                  :rules="updateRules"
                >
                  <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                    <label for="document_type">Document Type</label>
                    <b-form-select
                      id="document_type"
                      v-model="paymentRequest.document_type"
                      :options="documentTypes"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isBusy || paymentRequest.transaction_type === 'Without 2307'"
                      @change="onChangeParameter"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please Select Document Type --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="receipt type"
                  vid="receipt_type"
                  :rules="updateRules"
                >
                  <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                    <label for="receipt_type">Receipt Type</label>
                    <b-form-select
                      id="receipt_type"
                      v-model="paymentRequest.receipt_type"
                      :options="receiptTypes"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isBusy || paymentRequest.transaction_type === 'Without 2307'"
                      @change="onChangeParameter"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please Select Receipt Type --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="period from"
                  vid="period_from"
                  :rules="updateRules"
                >
                  <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                    <label for="period_from">Period From</label>
                    <b-input
                      id="period_from"
                      v-model="paymentRequest.period_from"
                      type="date"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isBusy || paymentRequest.transaction_type === 'Without 2307'"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="period to"
                  vid="period_to"
                  :rules="updateRules"
                >
                  <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                    <label for="period_to">Period To</label>
                    <b-input
                      id="period_to"
                      v-model="paymentRequest.period_to"
                      type="date"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isBusy || paymentRequest.transaction_type === 'Without 2307'"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="tax code"
                  vid="atc"
                  :rules="updateRules"
                >
                  <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                    <label for="atc">Tax Code <small>(Type any string to search)</small></label>
                    <v-select
                      id="atc"
                      v-model="selectedTaxCode"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :options="taxCodes"
                      label="code"
                      :disabled="isBusy || paymentRequest.transaction_type === 'Without 2307'"
                    >
                      <template #no-options="">
                        Type any string to search
                      </template>
                    </v-select>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="tax description"
                  vid="atc_description"
                  :rules="updateRules"
                >
                  <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                    <label for="tax_description">Tax Description</label>
                    <b-input
                      id="tax_description"
                      v-model="paymentRequest.atc_description"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :disabled="true"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="atc payment"
                  vid="atc_payment"
                  :rules="updateRules"
                >
                  <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                    <label for="atc_payment">ATC Payment</label>
                    <b-form-select
                      id="atc_payment"
                      v-model="paymentRequest.atc_payment"
                      :options="atcPayments"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isBusy || paymentRequest.transaction_type === 'Without 2307'"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please select payment date --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <b-form-group>
                  <label for="invoice_amount">Invoice Amount</label>
                  <b-input
                    id="invoice_amount"
                    v-model="paymentRequest.invoice_amount"
                    v-mask="currencyMask"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <ValidationProvider
                  #default="{ errors }"
                  name="amount"
                  vid="amount"
                  :rules="updateRules"
                >
                  <b-form-group>
                    <label for="amount">Amount (Exclusive of VAT)</label>
                    <b-input
                      id="amount"
                      v-model="paymentRequest.amount"
                      v-mask="currencyMask"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="enter amount"
                      autocomplete="off"
                      :disabled="isBusy || paymentRequest.document_type === 'Blank 2307' || paymentRequest.payment_nature === 'Blank 2307' || paymentRequest.receipt_type === 'Blank 2307'"
                      @blur="paymentRequest.amount=numberFormatter(paymentRequest.amount)"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="tax with held"
                  vid="tax_with_held"
                  :rules="updateRules"
                >
                  <b-form-group>
                    <label for="tax_with_held">Tax Withheld</label>
                    <b-input
                      id="tax_with_held"
                      v-model="paymentRequest.tax_with_held"
                      v-mask="currencyMask"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="enter tax withheld"
                      autocomplete="off"
                      :disabled="isBusy || paymentRequest.document_type === 'Blank 2307' || paymentRequest.payment_nature === 'Blank 2307' || paymentRequest.receipt_type === 'Blank 2307'"
                      @blur="paymentRequest.tax_with_held=numberFormatter(paymentRequest.tax_with_held)"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="amount to pay"
                  vid="pay_amount"
                  :rules="updateRules"
                >
                  <b-form-group>
                    <label for="pay_amount">Amount to Pay</label>
                    <b-input
                      id="pay_amount"
                      v-model="paymentRequest.pay_amount"
                      v-mask="currencyMask"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="enter amount to pay"
                      autocomplete="off"
                      :disabled="isBusy"
                      @blur="paymentRequest.pay_amount=numberFormatter(paymentRequest.pay_amount)"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <div
                  v-if="paymentRequest.transaction_type === 'With 2307'"
                  class="form-group"
                >
                  <b-form-checkbox
                    id="allow_input"
                    v-model="paymentRequest.supplier_manual_input"
                    name="allow_input"
                    value="1"
                    unchecked-value="0"
                    :disabled="isBusy || paymentRequest.transaction_type === 'Without 2307'"
                    @change="onManualnputChange"
                  >
                    Allow me to manually input supplier details
                  </b-form-checkbox>
                </div>

                <ValidationProvider
                  #default="{ errors }"
                  name="supplier trade name"
                  vid="trade_name"
                  rules=""
                >
                  <b-form-group v-if="parseInt(paymentRequest.supplier_manual_input) === 0 && paymentRequest.transaction_type === 'With 2307'">
                    <label for="trade_name">Supplier Trade Name <small>(Type any string to search)</small></label>
                    <v-select
                      id="trade_name"
                      v-model="selectedSupplier"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :options="suppliers"
                      label="trade_name"
                      :disabled="isBusy || paymentRequest.transaction_type === 'Without 2307'"
                      @search="onSearchSupplier"
                    >
                      <template #option="{ trade_name, tin }">
                        <strong>{{ trade_name }}</strong><br>
                        <span>{{ tin }}</span>
                      </template>
                      <template #no-options="">
                        Type any string to search
                      </template>
                    </v-select>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="supplier type"
                  vid="supplier_type"
                  rules=""
                >

                  <b-form-group v-if="parseInt(paymentRequest.supplier_manual_input) === 1 && paymentRequest.transaction_type === 'With 2307'">
                    <label for="supplier_type">Supplier Type</label>
                    <b-form-select
                      id="supplier_type"
                      v-model="paymentRequest.supplier_type"
                      :options="supplierTypes"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :disabled="isBusy"
                      @change="onSupplierTypeChange"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Please Select Supplier Type --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="supplier trade name"
                  vid="trade_name"
                  :rules="supplierTradeNameRules"
                >
                  <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                    <label for="manual_trade_name">Supplier Trade Name</label>
                    <b-input
                      id="manual_trade_name"
                      v-model="paymentRequest.trade_name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="off"
                      :disabled="isBusy || parseInt(paymentRequest.supplier_manual_input) === 0"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="supplier company name"
                  vid="company_name"
                  :rules="supplierCompanyNameRules"
                >
                  <b-form-group v-if="paymentRequest.supplier_type === 'Corporation' && paymentRequest.transaction_type === 'With 2307'">
                    <label for="manual_company_name">Supplier Company Name</label>
                    <b-input
                      id="manual_company_name"
                      v-model="paymentRequest.company_name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="off"
                      :disabled="isBusy || parseInt(paymentRequest.supplier_manual_input) === 0"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="proprietor first name"
                  vid="proprietor_first_name"
                  :rules="supplierProprietorRules"
                >
                  <b-form-group v-if="paymentRequest.supplier_type === 'Sole Proprietorship' && paymentRequest.transaction_type === 'With 2307'">
                    <label for="manual_proprietor_first_name">Proprietor First Name</label>
                    <b-input
                      id="manual_proprietor_first_name"
                      v-model="paymentRequest.proprietor_first_name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="off"
                      :disabled="isBusy || parseInt(paymentRequest.supplier_manual_input) === 0"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="proprietor middle name"
                  vid="proprietor_middle_name"
                  :rules="supplierProprietorRules"
                >
                  <b-form-group v-if="paymentRequest.supplier_type === 'Sole Proprietorship' && paymentRequest.transaction_type === 'With 2307'">
                    <label for="manual_proprietor_middle_name">Proprietor Middle Name</label>
                    <b-input
                      id="manual_proprietor_middle_name"
                      v-model="paymentRequest.proprietor_middle_name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="off"
                      :disabled="isBusy || parseInt(paymentRequest.supplier_manual_input) === 0"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="proprietor last name"
                  vid="proprietor_last_name"
                  :rules="supplierProprietorRules"
                >
                  <b-form-group v-if="paymentRequest.supplier_type === 'Sole Proprietorship' && paymentRequest.transaction_type === 'With 2307'">
                    <label for="manual_proprietor_last_name">Proprietor Last Name</label>
                    <b-input
                      id="manual_proprietor_last_name"
                      v-model="paymentRequest.proprietor_last_name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="off"
                      :disabled="isBusy || parseInt(paymentRequest.supplier_manual_input) === 0"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="supplier tin"
                  vid="tin"
                  :rules="supplierTinRules"
                >
                  <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                    <label for="manual_tin">Supplier Tin</label>
                    <b-input
                      id="manual_tin"
                      v-model="paymentRequest.tin"
                      v-mask="'###-###-###-####'"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="off"
                      :disabled="isBusy || parseInt(paymentRequest.supplier_manual_input) === 0"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  #default="{ errors }"
                  name="supplier address"
                  vid="address"
                  :rules="supplierAddressRules"
                >
                  <b-form-group v-if="paymentRequest.transaction_type === 'With 2307'">
                    <label for="manual_address">Supplier Address</label>
                    <b-textarea
                      id="manual_address"
                      v-model="paymentRequest.address"
                      rows="6"
                      max-rows="6"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="off"
                      :disabled="isBusy || parseInt(paymentRequest.supplier_manual_input) === 0 || paymentRequest.transaction_type === 'Without 2307'"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>

              </b-tab>

              <b-tab
                title="Attachments"
              >
                <b-button
                  v-if="paymentRequest.file_list.length>0"
                  size="sm"
                  variant="success"
                  class="mb-2"
                  @click="onPreviewAllAttachment"
                >
                  Preview All
                </b-button>
                <b-table
                  class="mt-1"
                  :fields="attachmentTableSettings.fields"
                  :items="paymentRequest.file_list"
                  responsive
                  striped
                  hover
                >
                  <template #cell(actions)="row">
                    <div class="text-nowrap">
                      <b-button
                        size="sm"
                        class="mr-1"
                        @click="onPreviewAttachment(row.item)"
                      >
                        Preview
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-tab>

              <b-tab
                title="Approval"
              >
                <ValidationObserver
                  ref="form"
                >
                  <form
                    novalidate
                    role="form2"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="approval status"
                      vid="approval_status"
                      rules="required"
                    >
                      <b-form-group>
                        <label for="approval_status">Approval Status</label>
                        <b-form-select
                          id="approval_status"
                          v-model="paymentRequest.approval_status"
                          :options="approvalStatuses"
                          type="text"
                          :state="errors.length > 0 ? false : null"
                          :disabled="isBusy"
                        >
                          <template #first>
                            <b-form-select-option
                              :value="null"
                              disabled
                            >
                              -- Please select approval status --
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider
                      #default="{ errors }"
                      name="tax accountant"
                      vid="tax_accountant"
                      :rules="taxAccountantRule"
                    >
                      <b-form-group v-if="paymentRequest.approval_status !== 'Processing'">
                        <label for="tax_accountant">Tax Accountant</label>
                        <b-form-select
                          id="tax_accountant"
                          v-model="paymentRequest.tax_accountant"
                          :options="taxAccountants"
                          type="text"
                          :state="errors.length > 0 ? false : null"
                          :disabled="isBusy"
                        >
                          <template #first>
                            <b-form-select-option
                              :value="null"
                              disabled
                            >
                              -- Please select tax accountant --
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider
                      #default="{ errors }"
                      name="approval remarks"
                      vid="approval_remarks"
                      :rules="approvalRemarksRule"
                    >
                      <b-form-group v-if="paymentRequest.approval_status !== 'Processing'">
                        <label for="approval_remarks">Approval Remarks</label>
                        <b-form-textarea
                          id="approval_remarks"
                          v-model="paymentRequest.approval_remarks"
                          rows="10"
                          max-rows="10"
                          :state="errors.length > 0 ? false : null"
                          placeholder="enter approval remarks"
                          autocomplete="off"
                          :disabled="isBusy"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </form>
                </ValidationObserver>
              </b-tab>

            </b-tabs>
          </b-card>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          :disabled="isBusy || (paymentRequest.transaction_type !== 'With 2307')"
          @click="onPreview2307"
        >
          Preview
        </b-button>

        <b-button
          variant="success"
          :disabled="isBusy"
          @click="ok()"
        >
          {{ paymentRequest.approval_status === 'Processing' ? 'Update' : paymentRequest.approval_status }}
        </b-button>

        <b-button
          variant="danger"
          :disabled="isBusy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-preview-2307"
      scrollable
      no-close-on-backdrop
      title="Preview 2307"
      size="lg"
    >
      <div
        id="printMe3"
      >
        <div class="container-2307">
          <b-img
            :src="require('@/assets/images/2307.jpg')"
            fluid
          />

          <span class="period-from-one">
            {{ periodFrom ? periodFrom[1] : '' }}
          </span>

          <span class="period-from-two">
            {{ periodFrom ? periodFrom[2] : '' }}
          </span>

          <span class="period-from-three">
            {{ periodFrom ? periodFrom[0] : '' }}
          </span>

          <span class="period-to-one">
            {{ periodTo ? periodTo[1] : '' }}
          </span>

          <span class="period-to-two">
            {{ periodTo ? periodTo[2] : '' }}
          </span>

          <span class="period-to-three">
            {{ periodTo ? periodTo[0] : '' }}
          </span>

          <span class="payee-tin-one">
            {{ taxIdentificationNumber ? taxIdentificationNumber[0] : null }}
          </span>

          <span class="payee-tin-two">
            {{ taxIdentificationNumber ? taxIdentificationNumber[1] : null }}
          </span>

          <span class="payee-tin-three">
            {{ taxIdentificationNumber ? taxIdentificationNumber[2] : null }}
          </span>

          <span class="payee-tin-four">
            {{ taxIdentificationNumber ? taxIdentificationNumber[3] : null }}
          </span>

          <span class="payee-name">
            {{ payeeName }}
          </span>

          <span class="payee-address">
            {{ paymentRequest.address }}
          </span>

          <div class="atc-description">
            {{ paymentRequest.atc_description }}
          </div>

          <span class="atc-code">
            {{ paymentRequest.atc }}
          </span>

          <span class="atc-payment-1st">
            {{ paymentRequest.atc_payment ? (paymentRequest.atc_payment.includes('1st') ? (parseFloat(paymentRequest.amount) === 0 ? '' : paymentRequest.amount) : '') : '' }}
          </span>

          <span class="atc-payment-2nd">
            {{ paymentRequest.atc_payment ? (paymentRequest.atc_payment.includes('2nd') ? (parseFloat(paymentRequest.amount) === 0 ? '' : paymentRequest.amount) : '') : '' }}
          </span>

          <span class="atc-payment-3rd">
            {{ paymentRequest.atc_payment ? (paymentRequest.atc_payment.includes('3rd') ? (parseFloat(paymentRequest.amount) === 0 ? '' : paymentRequest.amount) : '') : '' }}
          </span>

          <span class="atc-payment-total">
            {{ parseFloat(paymentRequest.amount) === 0 ? '' : paymentRequest.amount }}
          </span>

          <span class="atc-payment-total-down">
            {{ parseFloat(paymentRequest.amount) === 0 ? '' : paymentRequest.amount }}
          </span>

          <span class="atc-payment-tax">
            {{ parseFloat(paymentRequest.tax_with_held) === 0 ? '' : paymentRequest.tax_with_held }}
          </span>

          <span class="atc-payment-tax-total">
            {{ parseFloat(paymentRequest.tax_with_held) === 0 ? '' : paymentRequest.tax_with_held }}
          </span>

          <span class="payee-name-signature">
            {{ payeeName }}
          </span>

          <span class="document-series">
            {{ paymentRequest.document_series }}
          </span>

          <span class="document-number">
            {{ paymentRequest.document_number }}
          </span>

        </div>
      </div>
      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-preview-pdf"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachment [${previewFilename}]`"
      size="lg"
    >
      <vue-pdf-embed :source="preview" />

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-preview-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachment [${previewFilename}]`"
      size="lg"
    >
      <div id="printMe1">
        <b-img
          fluid
          :src="preview"
        />
      </div>

      <template #modal-footer="{cancel}">
        <b-button
          v-print="'#printMe1'"
          variant="success"
        >
          Print Attachment
        </b-button>

        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-preview-all-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachments`"
      size="lg"
    >
      <div id="printMe2">
        <table>
          <tr
            v-for="attachment in allAttachments"
            :key="attachment"
          >
            <td
              class="p-0 m-0"
              style="page-break-after: always"
            >
              <b-img
                fluid
                :src="attachment"
                style="height: 100vh;"
              />
            </td>
          </tr>
        </table>
      </div>

      <template #modal-footer="{cancel}">
        <b-button
          v-print="'#printMe2'"
          variant="success"
        >
          Print All
        </b-button>

        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { AxiosError, TaxAccountantPendingApprovalService, SharedSearchService, SharedListService } from '@/services'
import print from 'vue-print-nb'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'
import _ from 'lodash'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  decimalLimit: 2
})

export default {
  name: 'TaxtAccountantPendingApprovals',

  middleware: ['auth', 'tax-accountant'],

  metaInfo () {
    return {
      title: 'Pending Approvals'
    }
  },

  components: {
    VuePdfEmbed
  },

  directives: {
    print
  },

  mixins: [formatter, url],

  data () {
    return {
      isBusy: false,
      preview: null,
      previewFilename: '',
      allAttachments: [],
      taxCodes: [],
      selectedTaxCode: null,
      suppliers: [],
      selectedSupplier: null,
      taxAccountants: [],
      filterTransactionTypes: [
        { text: 'All', value: 'All' },
        { text: 'With 2307', value: 'With 2307' },
        { text: 'Without 2307', value: 'Without 2307' }
      ],
      filter: {
        transaction_type: 'All'
      },
      transactionTypes: [
        { text: 'With 2307', value: 'With 2307' },
        { text: 'Without 2307', value: 'Without 2307' }
      ],
      atcPayments: [
        { text: '1st Month of the Quarter', value: '1st Month' },
        { text: '2nd Month of the Quarter', value: '2nd Month' },
        { text: '3rd Month of the Quarter', value: '3rd Month' }
      ],
      paymentNatures: [
        { text: 'Services', value: 'Services' },
        { text: 'Goods', value: 'Goods' },
        { text: 'Rentals', value: 'Rentals' },
        { text: 'Professional Fee', value: 'Professional Fee' },
        { text: 'Blank 2307', value: 'Blank 2307' }
      ],
      documentTypes: [
        { text: 'Charge Invoice', value: 'Charge Invoice' },
        { text: 'Offical Receipt', value: 'Official Receipt' },
        { text: 'Sales Invoice', value: 'Sales Invoice' },
        { text: 'Cash Sales Invoice', value: 'Cash Sales Invoice' },
        { text: 'Cash Invoice', value: 'Cash Invoice' },
        { text: 'Payment Voucher', value: 'Payment Voucher' },
        { text: 'Blank 2307', value: 'Blank 2307' }
      ],
      receiptTypes: [
        { text: 'Vat-Reg', value: 'Vat-Reg' },
        { text: 'Non-Vat', value: 'Non-Vat' },
        { text: 'Blank 2307', value: 'Blank 2307' }
      ],
      supplierTypes: [
        { text: 'Sole Proprietorship', value: 'Sole Proprietorship' },
        { text: 'Corporation', value: 'Corporation' }
      ],
      paymentRequest: {
        id: 0,
        created_at: '',
        request_payee: '',
        request_amount: 0,
        transaction_type: '',
        request_status: '',
        budget_name: '',
        budget_actual_name: '',
        item_name: '',
        request_description: '',
        payment_mode: '',
        payment_action: '',
        bank: '',
        bank_account_number: '',
        bank_account_name: '',
        approval_one_name: '',
        approval_one_at: '',
        approval_one_remarks: '',
        approval_two_name: '',
        approval_two_at: '',
        approval_two_remarks: '',
        tax_accountant: null,
        payment_nature: '',
        document_type: '',
        receipt_type: '',
        period_from: '',
        period_to: '',
        atc: '',
        atc_description: '',
        atc_payment: null,
        document_number: '',
        document_series: '',
        amount: 0,
        invoice_amount: 0,
        tax_with_held: 0,
        pay_amount: 0,
        supplier_manual_input: 0,
        supplier_type: null,
        supplier_id: '',
        trade_name: '',
        company_name: '',
        proprietor_first_name: '',
        proprietor_middle_name: '',
        proprietor_last_name: '',
        tin: '',
        address: '',
        file_path: '',
        file_list: [],
        approval_status: null,
        approval_remarks: ''
      },
      approvalStatuses: [
        { text: 'Processing', value: 'Processing' },
        { text: 'Approved', value: 'Approved' },
        { text: 'Disapproved', value: 'Disapproved' }
      ],
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, label: 'request date', sortable: true },
          { key: 'request_payee' },
          { key: 'request_amount', formatter: this.numberFormatter, class: 'text-right' },
          { key: 'request_status' },
          { key: 'transaction_type' },
          { key: 'payment_nature' },
          { key: 'document_type' },
          { key: 'receipt_type' },
          { key: 'user_name', label: 'requested by' },
          { key: 'approval_one_at', label: 'noted at' },
          { key: 'approval_two_at', label: 'approved at' }
        ]
      },
      attachmentTableSettings: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename', sortable: false }
        ]
      },
      currencyMask
    }
  },

  computed: {
    approvalMessage () {
      if (this.paymentRequest.approval_status === 'Approved') {
        return 'YES, IM SURE I WANT TO APPROVED THIS REQUEST'
      }

      return 'YES, IM SURE I WANT TO DISAPPROVED THIS REQUEST'
    },

    taxAccountantRule () {
      if (this.paymentRequest.approval_status !== 'Processing') {
        return 'required'
      }

      return ''
    },

    approvalRemarksRule () {
      if (this.paymentRequest.approval_status === 'Disapproved') {
        return 'required|max:200'
      }

      if (this.paymentRequest.approval_status === 'Approved') {
        return 'max:200'
      }

      return ''
    },

    updateRules () {
      if (this.paymentRequest.transaction_type === 'With 2307' && this.paymentRequest.approval_status !== 'Disapproved') {
        return 'required'
      }

      return ''
    },

    supplierTradeNameRules () {
      if (this.paymentRequest.transaction_type === 'With 2307' && (this.paymentRequest.document_type === 'Blank 2307' || this.paymentRequest.receipt_type === 'Blank 2307')) {
        return ''
      } else if (this.paymentRequest.transaction_type === 'With 2307' && this.paymentRequest.document_type !== 'Blank 2307' && this.paymentRequest.receipt_type !== 'Blank 2307' && this.paymentRequest.approval_status !== 'Disapproved') {
        return 'required|max:200'
      }

      return 'max:200'
    },

    supplierCompanyNameRules () {
      if (this.paymentRequest.transaction_type === 'With 2307' && this.paymentRequest.supplier_type === 'Corporation' && (this.paymentRequest.document_type === 'Blank 2307' || this.paymentRequest.receipt_type === 'Blank 2307')) {
        return ''
      } else if (this.paymentRequest.transaction_type === 'With 2307' && this.paymentRequest.supplier_type === 'Corporation' && this.paymentRequest.document_type !== 'Blank 2307' && this.paymentRequest.receipt_type !== 'Blank 2307' && this.paymentRequest.approval_status !== 'Disapproved') {
        return 'required|max:200'
      }

      return 'max:200'
    },

    supplierProprietorRules () {
      if (this.paymentRequest.transaction_type === 'With 2307' && this.paymentRequest.supplier_type === 'Sole Proprietorship' && (this.paymentRequest.document_type === 'Blank 2307' || this.paymentRequest.receipt_type === 'Blank 2307')) {
        return ''
      } else if (this.paymentRequest.transaction_type === 'With 2307' && this.paymentRequest.supplier_type === 'Sole Proprietorship' && this.paymentRequest.document_type !== 'Blank 2307' && this.paymentRequest.receipt_type !== 'Blank 2307' && this.paymentRequest.approval_status !== 'Disapproved') {
        return 'required|max:50'
      }

      return 'max:50'
    },

    supplierTinRules () {
      if (this.paymentRequest.transaction_type === 'With 2307' && (this.paymentRequest.document_type === 'Blank 2307' || this.paymentRequest.receipt_type === 'Blank 2307')) {
        return ''
      } else if (this.paymentRequest.transaction_type === 'With 2307' && this.paymentRequest.document_type !== 'Blank 2307' && this.paymentRequest.receipt_type !== 'Blank 2307' && this.paymentRequest.approval_status !== 'Disapproved') {
        return 'required|min:16|max:16'
      }

      return 'min:16|max:16'
    },

    supplierAddressRules () {
      if (this.paymentRequest.transaction_type === 'With 2307' && (this.paymentRequest.document_type === 'Blank 2307' || this.paymentRequest.receipt_type === 'Blank 2307')) {
        return ''
      } else if (this.paymentRequest.transaction_type === 'With 2307' && this.paymentRequest.document_type !== 'Blank 2307' && this.paymentRequest.receipt_type !== 'Blank 2307' && this.paymentRequest.approval_status !== 'Disapproved') {
        return 'required|max:200'
      }

      return 'max:200'
    },

    payeeName () {
      if (this.paymentRequest.supplier_type === 'Corporation') {
        return this.paymentRequest.trade_name ? this.paymentRequest.trade_name : ''
      }

      const proprietorFirstName = this.paymentRequest.proprietor_first_name ? this.paymentRequest.proprietor_first_name : ''
      const proprietorMiddleName = this.paymentRequest.proprietor_middle_name ? this.paymentRequest.proprietor_middle_name : ''
      const proprietorLastName = this.paymentRequest.proprietor_last_name ? this.paymentRequest.proprietor_last_name : ''

      return `${proprietorFirstName} ${proprietorMiddleName} ${proprietorLastName}`
    },

    taxIdentificationNumber () {
      const str = this.paymentRequest.tin?.split('-')

      if (str) {
        if (str.length !== 4) {
          return null
        }
      }

      return str
    },

    periodFrom () {
      const str = this.paymentRequest.period_from?.split('-')

      if (str) {
        if (str.length !== 3) {
          return null
        }
      }

      return str
    },

    periodTo () {
      const str = this.paymentRequest.period_to?.split('-')

      if (str) {
        if (str.length !== 3) {
          return null
        }
      }

      return str
    }
  },

  watch: {
    'filter.transaction_type' () {
      this.$refs.table.refresh()
    },

    'paymentRequest.approval_status' () {
      this.paymentRequest.approval_remarks = ''
    },

    'selectedTaxCode' (value) {
      if (value) {
        this.paymentRequest.atc = value.code
        this.paymentRequest.atc_description = value.description
      } else {
        this.paymentRequest.atc = ''
        this.paymentRequest.atc_description = ''
      }
    },

    'selectedSupplier' (value) {
      if (value) {
        this.paymentRequest.supplier_id = value.id
        this.paymentRequest.supplier_type = value.supplier_type !== null ? value.supplier_type : 'Sole Proprietorship'
        this.paymentRequest.trade_name = value.trade_name
        this.paymentRequest.company_name = value.company_name
        this.paymentRequest.proprietor_first_name = value.proprietor_first_name
        this.paymentRequest.proprietor_middle_name = value.proprietor_middle_name
        this.paymentRequest.proprietor_last_name = value.proprietor_last_name
        this.paymentRequest.tin = value.tin
        this.paymentRequest.address = value.address
      } else {
        this.paymentRequest.supplier_id = 0
        this.paymentRequest.supplier_type = 'Sole Proprietorship'
        this.paymentRequest.trade_name = ''
        this.paymentRequest.company_name = ''
        this.paymentRequest.proprietor_first_name = ''
        this.paymentRequest.proprietor_middle_name = ''
        this.paymentRequest.proprietor_last_name = ''
        this.paymentRequest.tin = ''
        this.paymentRequest.address = ''
      }
    }
  },

  mounted () {
    core.index()
    this.fetchTaxCodeList()
    this.fetchTaxAccountList()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await TaxAccountantPendingApprovalService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_type=${this.filter.transaction_type}&filter_text=${ctx.filter}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async fetchTaxCodeList () {
      await SharedListService.getTaxCodeList().then(({ data }) => {
        this.taxCodes = data
      })
    },

    async fetchTaxAccountList () {
      await SharedListService.getTaxAccountantList().then(({ data }) => {
        data.forEach(item => {
          this.taxAccountants.push({
            text: item.accountant_name,
            value: item.id
          })
        })
      })
    },

    onSearchSupplier (search, loading) {
      if (search.length) {
        loading(true)
        this.searchSupplier(search, loading, this)
      }
    },

    searchSupplier: _.debounce(async (search, loading, vm) => {
      await SharedSearchService.getSupplier(`search_string=${search}`).then(({ data }) => {
        loading(false)
        vm.suppliers = data
      }).catch(() => {
        loading(false)
      })
    }, 1000),

    onManualnputChange () {
      this.selectedSupplier = null
      this.paymentRequest.supplier_id = 0
      this.paymentRequest.trade_name = ''
      this.paymentRequest.company_name = ''
      this.paymentRequest.proprietor_first_name = ''
      this.paymentRequest.proprietor_middle_name = ''
      this.paymentRequest.proprietor_last_name = ''
      this.paymentRequest.tin = ''
      this.paymentRequest.address = ''
    },

    onSupplierTypeChange () {
      this.paymentRequest.supplier_id = 0
      this.paymentRequest.trade_name = ''
      this.paymentRequest.company_name = ''
      this.paymentRequest.proprietor_first_name = ''
      this.paymentRequest.proprietor_middle_name = ''
      this.paymentRequest.proprietor_last_name = ''
      this.paymentRequest.tin = ''
      this.paymentRequest.address = ''
    },

    onTransactionTypeChange (value) {
      if (value === 'Without 2307') {
        this.paymentRequest.supplier_type = null
        this.paymentRequest.payment_nature = null
        this.paymentRequest.document_type = null
        this.paymentRequest.receipt_type = null
        this.paymentRequest.period_from = null
        this.paymentRequest.period_to = null
        this.paymentRequest.atc_payment = null
        this.paymentRequest.document_number = ''
        this.paymentRequest.document_series = ''
        this.selectedTaxCode = null
        this.selectedSupplier = null
        this.paymentRequest.supplier_type = null
        this.paymentRequest.trade_name = null
        this.paymentRequest.company_name = null
        this.paymentRequest.proprietor_first_name = null
        this.paymentRequest.proprietor_middle_name = null
        this.paymentRequest.proprietor_last_name = null
        this.paymentRequest.tin = null
        this.paymentRequest.address = null
      }
    },

    onShowApprovalForm (item) {
      this.paymentRequest.id = item.id
      this.paymentRequest.created_at = this.dateTimeFormatter(item.created_at)
      this.paymentRequest.request_payee = item.request_payee
      this.paymentRequest.request_amount = this.numberFormatter(item.request_amount)
      this.paymentRequest.transaction_type = item.transaction_type
      this.paymentRequest.request_status = item.request_status
      this.paymentRequest.budget_name = item.budget_name
      this.paymentRequest.budget_actual_name = item.budget_actual_name
      this.paymentRequest.item_name = item.item_name
      this.paymentRequest.request_description = item.request_description
      this.paymentRequest.payment_mode = item.payment_mode
      this.paymentRequest.payment_action = item.payment_action
      this.paymentRequest.bank = item.bank
      this.paymentRequest.bank_account_number = item.bank_account_number
      this.paymentRequest.bank_account_name = item.bank_account_name
      this.paymentRequest.user_name = item.user_name
      this.paymentRequest.approval_one_name = item.approval_one_name
      this.paymentRequest.approval_one_at = item.approval_one_at
      this.paymentRequest.approval_one_remarks = item.approval_one_remarks
      this.paymentRequest.approval_two_name = item.approval_two_name
      this.paymentRequest.approval_two_at = item.approval_two_at
      this.paymentRequest.approval_two_remarks = item.approval_two_remarks
      this.paymentRequest.period_from = item.period_from === null ? '' : this.dateFormatter(item.period_from)
      this.paymentRequest.period_to = item.period_to === null ? '' : this.dateFormatter(item.period_to)
      this.selectedTaxCode = { code: item.atc, description: item.atc_description }
      this.paymentRequest.atc_payment = item.atc_payment
      this.paymentRequest.document_number = item.document_number
      this.paymentRequest.document_series = item.document_series
      this.paymentRequest.invoice_amount = this.numberFormatter(item.invoice_amount)
      this.paymentRequest.amount = this.numberFormatter(item.amount)
      this.paymentRequest.tax_with_held = this.numberFormatter(item.tax_with_held)
      this.paymentRequest.pay_amount = this.numberFormatter(item.pay_amount)
      this.paymentRequest.supplier_manual_input = item.supplier_id === null ? 1 : 0
      this.paymentRequest.supplier_id = item.supplier_id
      this.paymentRequest.supplier_type = item.transaction_type === 'With 2307' ? (item.supplier_type !== null ? item.supplier_type : 'Sole Proprietorship') : 'Sole Proprietorship'
      this.paymentRequest.trade_name = item.trade_name
      this.paymentRequest.company_name = item.company_name
      this.paymentRequest.proprietor_first_name = item.proprietor_first_name
      this.paymentRequest.proprietor_middle_name = item.proprietor_middle_name
      this.paymentRequest.proprietor_last_name = item.proprietor_last_name
      this.paymentRequest.tin = item.tin
      this.paymentRequest.address = item.address
      this.paymentRequest.payment_nature = item.payment_nature
      this.paymentRequest.document_type = item.document_type
      this.paymentRequest.receipt_type = item.receipt_type
      this.paymentRequest.file_path = item.file_path
      this.paymentRequest.file_list = item.attachments
      this.paymentRequest.approval_status = 'Processing'
      this.paymentRequest.tax_accountant = item.tax_accountant_id
      this.paymentRequest.approvalMessage = ''

      if (this.paymentRequest.supplier_manual_input === 0) {
        this.selectedSupplier = {
          supplier_type: item.supplier_type,
          id: item.supplier_id,
          trade_name: item.trade_name,
          company_name: item.company_name,
          proprietor_first_name: item.proprietor_first_name,
          proprietor_middle_name: item.proprietor_middle_name,
          proprietor_last_name: item.proprietor_last_name,
          tin: item.tin,
          address: item.address
        }
      }

      this.$bvModal.show('modal-approval-form')
    },

    async onPreview2307 () {
      this.$bvModal.show('modal-preview-2307')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Do you really to save changes on tax details?'

          if (this.paymentRequest.approval_status !== 'Processing') {
            title = `Do you really want to ${this.paymentRequest.approval_status.toLowerCase()} this transaction?`
          }

          this.$swal.fire({
            icon: 'question',
            title: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPutSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger'
          })
        }
      })
    },

    async onFormPutSubmit () {
      return new Promise((resolve, reject) => {
        this.isBusy = true
        const params = {
          id: this.paymentRequest.id,
          approval_status: this.paymentRequest.approval_status,
          approval_remarks: this.paymentRequest.approval_remarks,
          transaction_type: this.paymentRequest.transaction_type,
          payment_nature: this.paymentRequest.payment_nature,
          document_type: this.paymentRequest.document_type,
          receipt_type: this.paymentRequest.receipt_type,
          period_from: this.paymentRequest.period_from,
          period_to: this.paymentRequest.period_to,
          atc: this.paymentRequest.atc,
          atc_description: this.paymentRequest.atc_description,
          atc_payment: this.paymentRequest.atc_payment,
          document_number: this.paymentRequest.document_number,
          document_series: this.paymentRequest.document_series,
          amount: this.paymentRequest.amount.replaceAll(',', ''),
          tax_with_held: this.paymentRequest.tax_with_held.replaceAll(',', ''),
          pay_amount: this.paymentRequest.pay_amount.replaceAll(',', ''),
          supplier_type: this.paymentRequest.tin === null ? null : this.paymentRequest.supplier_type,
          supplier_id: parseInt(this.paymentRequest.supplier_manual_input) === 1 ? 0 : this.paymentRequest.supplier_id,
          trade_name: this.paymentRequest.trade_name,
          company_name: this.paymentRequest.company_name,
          proprietor_first_name: this.paymentRequest.proprietor_first_name,
          proprietor_middle_name: this.paymentRequest.proprietor_middle_name,
          proprietor_last_name: this.paymentRequest.proprietor_last_name,
          tin: this.paymentRequest.tin,
          address: this.paymentRequest.address,
          tax_accountant: this.paymentRequest.tax_accountant
        }

        TaxAccountantPendingApprovalService.put(params).then(response => {
          this.isBusy = false
          if (this.paymentRequest.approval_status !== 'Processing') {
            this.$bvModal.hide('modal-approval-form')
          }
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: response.data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            if (this.paymentRequest.approval_status === 'Processing') {
              var row = _.find(this.$refs.table.localItems, { id: params.id })
              if (row) {
                row.transaction_type = params.transaction_type
                row.payment_nature = params.payment_nature
                row.document_type = params.document_type
                row.receipt_type = params.receipt_type
                row.period_from = params.period_from
                row.period_to = params.period_to
                row.atc = params.atc
                row.atc_description = params.atc_description
                row.atc_payment = params.atc_payment
                row.document_series = params.document_series
                row.document_number = params.document_number
                row.amount = params.amount
                row.tax_with_held = params.tax_with_held
                row.pay_amount = params.pay_amount
                row.supplier_type = params.supplier_type
                row.supplier_id = params.supplier_id === 0 ? null : params.supplier_id
                row.trade_name = params.trade_name
                row.company_name = params.company_name
                row.proprietor_first_name = params.proprietor_first_name
                row.proprietor_middle_name = params.proprietor_middle_name
                row.proprietor_last_name = params.proprietor_last_name
                row.tin = params.tin
                row.address = params.address
                this.paymentRequest.transaction_type = params.transaction_type
                this.paymentRequest.payment_nature = params.payment_nature
                this.paymentRequest.document_type = params.document_type
                this.paymentRequest.receipt_type = params.receipt_type
                this.paymentRequest.period_from = params.period_from
                this.paymentRequest.period_to = params.period_to
                this.paymentRequest.atc = params.atc
                this.paymentRequest.atc_description = params.atc_description
                this.paymentRequest.atc_payment = params.atc_payment
                this.paymentRequest.document_series = params.document_series
                this.paymentRequest.document_number = params.document_number
                this.paymentRequest.amount = params.amount
                this.paymentRequest.tax_with_held = params.tax_with_held
                this.paymentRequest.pay_amount = params.pay_amount
                this.paymentRequest.supplier_manual_input = params.supplier_id === 0 ? 1 : 0
                this.paymentRequest.supplier_type = params.supplier_type
                this.paymentRequest.supplier_id = params.supplier_id === 0 ? null : params.supplier_id
                this.paymentRequest.trade_name = params.trade_name
                this.paymentRequest.company_name = params.company_name
                this.paymentRequest.proprietor_first_name = params.proprietor_first_name
                this.paymentRequest.proprietor_middle_name = params.proprietor_middle_name
                this.paymentRequest.proprietor_last_name = params.proprietor_last_name
                this.paymentRequest.tin = params.tin
                this.paymentRequest.address = params.address
              }
            } else {
              this.$refs.table.refresh()
            }
          })
        }).catch(error => {
          this.isBusy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger'
              })
              resolve(error)
            }
          }
        })
      })
    },

    async onPreviewAttachment (item) {
      this.previewFilename = item.file_original_name
      this.preview = this.getImageUrl(`${this.paymentRequest.file_path}/${item.file_hash_name}`)
      this.$bvModal.show('modal-preview-image')
    },

    onPreviewAllAttachment () {
      this.allAttachments = []

      this.paymentRequest.file_list.forEach(file => {
        this.allAttachments.push(this.getImageUrl(`${this.paymentRequest.file_path}/${file.file_hash_name}`))
      })

      this.$bvModal.show('modal-preview-all-image')
    },

    onChangeParameter (value) {
      if (value === 'Blank 2307') {
        this.paymentRequest.amount = this.numberFormatter(0)
        this.paymentRequest.tax_with_held = this.numberFormatter(0)
      }
    }
  }
}
</script>
